/*------------- #OVERLAY MENU --------------*/


.overlay-menu-wrap {
  position: fixed;
  background-image: url(../img/body-bg.png);
  top: 0;
  right: 0;
  width: 80%;
  max-height: 0;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, max-height .45s;
  overflow: hidden;
  z-index: 999;
  box-shadow: 40px 0 60px 100px rgba(61, 67, 79, 0.2);

  .perfect-scroll {
	max-height: 100%;
	height: 100%;
  }

  &.open {
	opacity: 1;
	visibility: visible;
	max-height: 100%;

	.overlay-menu-item {
	  animation: fadeInRight .5s ease forwards;
	  animation-delay: .35s;

	  &:nth-of-type(2) {
		animation-delay: .4s;
	  }
	  &:nth-of-type(3) {
		animation-delay: .45s;
	  }
	  &:nth-of-type(4) {
		animation-delay: .50s;
	  }
	  &:nth-of-type(5) {
		animation-delay: .55s;
	  }
	  &:nth-of-type(6) {
		animation-delay: .60s;
	  }
	  &:nth-of-type(7) {
		animation-delay: .65s;
	  }
	  &:nth-of-type(8) {
		animation-delay: .70s;
	  }
	  &:nth-of-type(9) {
		animation-delay: .75s;
	  }
	  &:nth-of-type(10) {
		animation-delay: .8s;
	  }
	}
  }
}

.overlay-menu {
  position: relative;
  max-width: 80%;
  margin: 0 auto;

  .form--search {
	padding: 0 50px;
  }
}

.overlay-menu-list {
  list-style: none;
  padding: 30px 0 0;
  margin: 0 auto 120px;
  display: inline-block;
  position: relative;
  text-align: right;
  z-index: 5;
}

.overlay-menu-item {
  display: block;
  font-size: 60px;
  position: relative;
  opacity: 0;
  padding-right: 150px;
  line-height: 1.5em;

  a {
	display: block;
	position: relative;
	text-decoration: none;
	overflow: hidden;

	&:hover:after,
	&:focus:after,
	&:active:after {
	  width: 100%;
	}

	&:hover,
	&:focus,
	&:active {
	  color: $primary-color;
	}

	&:after {
	  content: '';
	  position: absolute;
	  bottom: 0;
	  left: 50%;
	  width: 0;
	  transform: translateX(-50%);
	  height: 3px;
	  background: $primary-color;
	  transition: all .35s ease;
	}
  }

  .sub-menu {
	position: absolute;
	transition: all .6s ease;
	top: 20px;
	right: 0;
	transform: translate(80%);
	opacity: 0;
	visibility: hidden;
	width: 300px;
	max-width: 100%;
  }
}

@keyframes fadeInRight {
  0% {
	opacity: 0;
	transform: translateX(20%);
  }
  100% {
	opacity: 1;
	transform: translateX(0);
  }
}

.sub-menu {
  text-align: left;

  .overlay-menu-item {
	font-size: 24px;
	padding-right: 0;
	padding-bottom: 12px;
  }

  &.open {
	opacity: 1;
	visibility: visible;
	transform: translate(100%);
  }

  &.sub-menu-bottom {
	top: auto;
	bottom: -10px;
  }
}


/*------------- #OVERLAY MENU STYLE 2 --------------*/

.overlay-menu--style2 {
  width: auto;
  padding: 0 150px;

  .overlay-menu {
	max-width: 100%;
  }

  .overlay-menu-item {
	padding-right: 0;

	.sub-menu.open {
	  right: auto;
	  transform: translate(0);
	  overflow: visible;
	  max-height: 350px;
	  margin: 20px 0;
	}
  }

  .overlay-menu-list {
	text-align: left;
  }

  .sub-menu {
	top: auto;
	right: auto;
	transform: translate(0);
	max-width: 100%;
	margin:0;
	position: relative;
	max-height: 0;
	overflow: hidden;
  }
}


/*------------- #OVERLAY MENU STYLE 3 (fullscreen) --------------*/

.overlay-menu--style3 {
  width: 100%;

  .overlay-menu-list {
	text-align: center;
	width: 100%;
  }

  .overlay-menu {
	max-width: 500px;
  }

  .overlay-menu-item {
	padding-right: 0;

	.sub-menu.open {
	  right: auto;
	  transform: translate(0);
	  overflow: visible;
	  max-height: 350px;
	  margin: 20px auto;
	}
  }

  .sub-menu {
	top: auto;
	right: auto;
	transform: translate(0);
	max-width: 100%;
	margin: 0 auto;
	position: relative;
	max-height: 0;
	overflow: hidden;
	text-align: center;
  }
}


/*------------- Responsive Mode --------------*/


@media (max-width: 1200px) {
  .overlay-menu-item {
	padding-right: 50px;
  }
}

@media (max-width: 1024px) {
  .overlay-menu-wrap {
	width: 100%;
  }

  .overlay-menu--style2 {
	padding: 0;
  }

  .overlay-menu--style2 .overlay-menu-list {
	text-align: center;
  }

  .overlay-menu--style2 .overlay-menu {
	text-align: center;
  }

  .overlay-menu--style2 .overlay-menu-item .sub-menu.open {
	margin: 20px auto;
  }

  .overlay-menu--style2 .sub-menu {
	text-align: center;
	margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .overlay-menu-item {
	font-size: 30px;

	.sub-menu {
	  top: auto;
	  left: auto;
	  transform: translate(0);
	  max-width: 100%;
	  margin: 0 auto;
	  position: relative;
	  max-height: 0;
	  overflow: hidden;
	  text-align: center;
	}
  }

  .sub-menu .overlay-menu-item {
	font-size: 20px;
  }

  .overlay-menu-list {
	text-align: center;
	padding: 30px 10px 0;
	width: 100%;
	margin-bottom: 40px;
  }

  .overlay-menu {
	max-width: 500px;
	overflow: hidden;
  }

  .overlay-menu-item {
	padding-right: 0;

	.sub-menu.open {
	  left: auto;
	  overflow: visible;
	  max-height: 350px;
	  margin: 20px auto;
	}
  }
}