/*------------- #FOOTER --------------*/



.footer {
  padding: 60px 0;

  .w-contacts {
	text-align: right;
  }
}

.sub-footer {
  padding: 60px 0;
  position: relative;
  color: $sub-footer-text;

  .divider {
	margin-bottom: 70px;
	z-index: -10;
  }


  span {
	font-size: 14px;
	display: block;
  }

  a {

	&:hover {
	  color: $primary-color;
	}
  }
}

.copyright-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-design {
  display: inline-flex;
  align-items: center;
  text-align: left;

  img {
	margin-right: 13px;
  }

  .sup-title {
	font-size: 11px;
	line-height: 1;
  }

  &.logo-design-crumina {
	margin-left: 15px;

	img {
	  width: 40px;
	  height: 40px;
	}
  }
}



/*------------- Responsive Mode --------------*/


@media (max-width: 799px) {
  .footer .widget {
	text-align: center;
  }

  .w-info .site-logo {
	justify-content: center;
  }

  .sub-footer {
	padding: 50px 0 0;
  }

  .footer {
	padding: 30px 0;
  }

  .sub-footer .divider {
	margin-bottom: 30px;
  }

  .copyright-wrapper {
	flex-direction: column;
	align-items: center;
	text-align: center;

	& > * + * {
	  margin-top: 20px;
	}
  }
}