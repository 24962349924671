/*------------- #TABLE OF CONTENTS --------------*/

/*------------------------------------------------------------------

1. Blocks / Styles for Blocks
	1.1 Acordion
	1.2 Alerts messages
	1.3 Blogs
	1.4 Breadcrumbs
	1.5 Buttons
	1.6 Case details
	1.7 Category list
	1.8 Checkboxes
	1.9 Clients
	1.10 Comments
	1.11 Contacts
	1.12 Counters
	1.13 Dividers
	1.14 Double image
	1.15 Forms
	1.16 Heading
	1.17 Info boxes
	1.18 Module i-box operand
	1.19 Move background
	1.20 Navigation
	1.21 Our video
	1.22 Pie charts
	1.23 Popup windows
	1.24 Portfolio
	1.25 Pricing tables
	1.26 Promo block
	1.27 Quotes
	1.28 Radio btn
	1.29 Skills
	1.30 Sliders
	1.31 Socials
	1.32 Tables
	1.33 Teammembers
	1.34 Testimonials
	1.35 Range Sliders
	1.35 Language Switcher

2. Plugins / Styles for plugins
	2.1 Datepicker
	2.2 Select2
	2.3 Swiper
	2.4 Ion Range Slider
	2.5 Perfect Scroll

3. Theme Styles / Main Theme Styles
	3.1 Content block
	3.2 Footer
	3.3 Global
	3.4 Grid
	3.5 Header
	3.6 Magnific popup
	3.7 Mixins
	3.8 Normalize
	3.9 Overlay menu
	3.10 Page 404
	3.11 Tabs
	3.12 Typography
	3.13 Variables

4. Widgets / Styles for all Widgets
	4.1 Widget About
	4.2 Widget Banner
	4.3 Widget Calendar
	4.4 Widget Category
	4.5 Widget Category links
	4.6 Widget Contacts
	4.7 Widget Highlights
	4.8 Widget Info
	4.9 Widget Latest news
	4.10 Widget Popular posts
	4.11 Widget Popular products
	4.12 Widget Popular tags
	4.13 Widget Recent Comments
	4.14 Widget Search
	4.15 Widget Shared posts
	4.16 Widget Socials counter
	4.17 Widget Socials subscribe
	4.18 Widget Tags




-------------------------------------------------------------------*/