/*------------- #HEADER --------------*/


.header {
  position: fixed;
  width: 100%;
  padding: 30px 0;
  z-index: 999;
  left: 0;
  transition: all .3s ease;

  &.headroom--not-top {
	background-color: $white-color;
	box-shadow: 15px 0 40px 0 rgba(61,67,79,.08);
	padding: 15px 0;
  }
}

.header-spacer {
  display: block;
  width: 100%;
}

.header-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.open-main-menu {
  display: flex;
  align-items: center;

  &:hover {
	span {
	  border-color: $primary-color;
	  width: 14px;
	  height: 14px;
	  background-color: $primary-color;
	}
  }

  span {
	display: block;
	transition: all .3s ease;
	border-radius: 100%;
	margin: 0 3px;
	border: 3px solid;

	&:first-of-type {
	  width: 10px;
	  height: 10px;
	  background-color: $secondary-color;
	}

	&:nth-child(2) {
	  width: 14px;
	  height: 14px;
	  border: 3px solid $secondary-color;
	}

	&:last-of-type {
	  width: 16px;
	  height: 16px;
	  background-color: $secondary-color;
	}
  }
}

/*================= Styles for sticky-Header =========*/


.animated {
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  -o-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  will-change: transform,opacity;

  @media (max-width: 480px) {
	animation-fill-mode: none;
	will-change: unset;
  }
}

@-webkit-keyframes swingInX {
  0% {
	-webkit-transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	-webkit-transform:perspective(400px) rotateX(0deg)
  }
}
@-moz-keyframes swingInX {
  0% {
	-moz-transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	-moz-transform:perspective(400px) rotateX(0deg)
  }
}
@-o-keyframes swingInX {
  0% {
	-o-transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	-o-transform:perspective(400px) rotateX(0deg)
  }
}
@keyframes swingInX {
  0% {
	transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	transform:perspective(400px) rotateX(0deg)
  }
}
.animated.swingInX {
  -webkit-transform-origin:top;
  -moz-transform-origin:top;
  -ie-transform-origin:top;
  -o-transform-origin:top;
  transform-origin:top;
  -webkit-backface-visibility:visible !important;
  -webkit-animation-name:swingInX;
  -moz-backface-visibility:visible !important;
  -moz-animation-name:swingInX;
  -o-backface-visibility:visible !important;
  -o-animation-name:swingInX;
  backface-visibility:visible !important;
  animation-name:swingInX;

  @media (max-width: 480px) {
	animation-name: none;
	transform-origin: unset;
  }
}
@-webkit-keyframes swingOutX {
  0% {
	-webkit-transform:perspective(400px) rotateX(0deg)
  }
  100% {
	-webkit-transform:perspective(400px) rotateX(-90deg)
  }
}
@-moz-keyframes swingOutX {
  0% {
	-moz-transform:perspective(400px) rotateX(0deg)
  }
  100% {
	-moz-transform:perspective(400px) rotateX(-90deg)
  }
}
@-o-keyframes swingOutX {
  0% {
	-o-transform:perspective(400px) rotateX(0deg)
  }
  100% {
	-o-transform:perspective(400px) rotateX(-90deg)
  }
}
@keyframes swingOutX {
  0% {
	transform:perspective(400px) rotateX(0deg)
  }
  100% {
	transform:perspective(400px) rotateX(-90deg)
  }
}
.animated.swingOutX {
  -webkit-transform-origin:top;
  -webkit-animation-name:swingOutX;
  -webkit-backface-visibility:visible !important;
  -moz-animation-name:swingOutX;
  -moz-backface-visibility:visible !important;
  -o-animation-name:swingOutX;
  -o-backface-visibility:visible !important;
  animation-name:swingOutX;
  backface-visibility:visible !important;

  @media (max-width: 480px) {
	animation-name: none;
	transform-origin: unset;
  }
}
@-webkit-keyframes slideDown {
  0% {
	-webkit-transform:translateY(-100%)
  }
  100% {
	-webkit-transform:translateY(0)
  }
}
@-moz-keyframes slideDown {
  0% {
	-moz-transform:translateY(-100%)
  }
  100% {
	-moz-transform:translateY(0)
  }
}
@-o-keyframes slideDown {
  0% {
	-o-transform:translateY(-100%)
  }
  100% {
	-o-transform:translateY(0)
  }
}
@keyframes slideDown {
  0% {
	transform:translateY(-100%)
  }
  100% {
	transform:translateY(0)
  }
}
.animated.slideDown {
  -webkit-animation-name:slideDown;
  -moz-animation-name:slideDown;
  -o-animation-name:slideDown;
  animation-name:slideDown;

  @media (max-width: 480px) {
	animation-name: none;
  }
}
@-webkit-keyframes slideUp {
  0% {
	-webkit-transform:translateY(0)
  }
  100% {
	-webkit-transform:translateY(-100%)
  }
}
@-moz-keyframes slideUp {
  0% {
	-moz-transform:translateY(0)
  }
  100% {
	-moz-transform:translateY(-100%)
  }
}
@-o-keyframes slideUp {
  0% {
	-o-transform:translateY(0)
  }
  100% {
	-o-transform:translateY(-100%)
  }
}
@keyframes slideUp {
  0% {
	transform:translateY(0)
  }
  100% {
	transform:translateY(-100%)
  }
}
.animated.slideUp {
  -webkit-animation-name:slideUp;
  -moz-animation-name:slideUp;
  -o-animation-name:slideUp;
  animation-name:slideUp;

  @media (max-width: 480px) {
	animation-name: none;
  }
}
@-webkit-keyframes swingInX {
  0% {
	-webkit-transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	-webkit-transform:perspective(400px) rotateX(0deg)
  }
}
@-moz-keyframes swingInX {
  0% {
	-moz-transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	-moz-transform:perspective(400px) rotateX(0deg)
  }
}
@-o-keyframes swingInX {
  0% {
	-o-transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	-o-transform:perspective(400px) rotateX(0deg)
  }
}
@keyframes swingInX {
  0% {
	transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	transform:perspective(400px) rotateX(0deg)
  }
}


@-webkit-keyframes flipInX {
  0% {
	-webkit-transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
  100% {
	-webkit-transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
}
@-moz-keyframes flipInX {
  0% {
	-moz-transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
  100% {
	-moz-transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
}
@-o-keyframes flipInX {
  0% {
	-o-transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
  100% {
	-o-transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
}
@keyframes flipInX {
  0% {
	transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
  100% {
	transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
}
.animated.flipInX {
  -webkit-backface-visibility:visible !important;
  -webkit-animation-name:flipInX;
  -moz-backface-visibility:visible !important;
  -moz-animation-name:flipInX;
  -o-backface-visibility:visible !important;
  -o-animation-name:flipInX;
  backface-visibility:visible !important;
  animation-name:flipInX;

  @media (max-width: 480px) {
	animation-name: none;
  }
}
@-webkit-keyframes flipOutX {
  0% {
	-webkit-transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
  100% {
	-webkit-transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
}
@-moz-keyframes flipOutX {
  0% {
	-moz-transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
  100% {
	-moz-transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
}
@-o-keyframes flipOutX {
  0% {
	-o-transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
  100% {
	-o-transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
}
@keyframes flipOutX {
  0% {
	transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
  100% {
	transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
}
.animated.flipOutX {
  -webkit-animation-name:flipOutX;
  -webkit-backface-visibility:visible !important;
  -moz-animation-name:flipOutX;
  -moz-backface-visibility:visible !important;
  -o-animation-name:flipOutX;
  -o-backface-visibility:visible !important;
  animation-name:flipOutX;
  backface-visibility:visible !important;

  @media (max-width: 480px) {
	animation-name: none;
  }
}
@-webkit-keyframes bounceInDown {
  0% {
	opacity:0;
	-webkit-transform:translateY(-200px)
  }
  60% {
	opacity:1;
	-webkit-transform:translateY(30px)
  }
  80% {
	-webkit-transform:translateY(-10px)
  }
  100% {
	-webkit-transform:translateY(0)
  }
}
@-moz-keyframes bounceInDown {
  0% {
	opacity:0;
	-moz-transform:translateY(-200px)
  }
  60% {
	opacity:1;
	-moz-transform:translateY(30px)
  }
  80% {
	-moz-transform:translateY(-10px)
  }
  100% {
	-moz-transform:translateY(0)
  }
}
@-o-keyframes bounceInDown {
  0% {
	opacity:0;
	-o-transform:translateY(-200px)
  }
  60% {
	opacity:1;
	-o-transform:translateY(30px)
  }
  80% {
	-o-transform:translateY(-10px)
  }
  100% {
	-o-transform:translateY(0)
  }
}
@keyframes bounceInDown {
  0% {
	opacity:0;
	transform:translateY(-200px)
  }
  60% {
	opacity:1;
	transform:translateY(30px)
  }
  80% {
	transform:translateY(-10px)
  }
  100% {
	transform:translateY(0)
  }
}
.animated.bounceInDown {
  -webkit-animation-name:bounceInDown;
  -moz-animation-name:bounceInDown;
  -o-animation-name:bounceInDown;
  animation-name:bounceInDown;

  @media (max-width: 480px) {
	animation-name: none;
  }
}
@-webkit-keyframes bounceOutUp {
  0% {
	-webkit-transform:translateY(0)
  }
  30% {
	opacity:1;
	-webkit-transform:translateY(20px)
  }
  100% {
	opacity:0;
	-webkit-transform:translateY(-200px)
  }
}
@-moz-keyframes bounceOutUp {
  0% {
	-moz-transform:translateY(0)
  }
  30% {
	opacity:1;
	-moz-transform:translateY(20px)
  }
  100% {
	opacity:0;
	-moz-transform:translateY(-200px)
  }
}
@-o-keyframes bounceOutUp {
  0% {
	-o-transform:translateY(0)
  }
  30% {
	opacity:1;
	-o-transform:translateY(20px)
  }
  100% {
	opacity:0;
	-o-transform:translateY(-200px)
  }
}
@keyframes bounceOutUp {
  0% {
	transform:translateY(0)
  }
  30% {
	opacity:1;
	transform:translateY(20px)
  }
  100% {
	opacity:0;
	transform:translateY(-200px)
  }
}
.animated.bounceOutUp {
  -webkit-animation-name:bounceOutUp;
  -moz-animation-name:bounceOutUp;
  -o-animation-name:bounceOutUp;
  animation-name:bounceOutUp;

  @media (max-width: 480px) {
	animation-name: none;
	transform-origin: unset;
  }
}

.site-logo {
  display: flex;
  align-items: center;

  img {
	margin-right: 13px;
  }

  .woox-icon {
	width: 100px;
	fill: $secondary-color;
  }
}


/*================= Responsive Mode ============*/

@media (max-width: 768px) {
  .site-logo img {
	max-width: 40px;
  }
}

@media (max-width: 460px) {
  .header-content-wrapper {
	flex-wrap: wrap;

	.site-logo {
	  width: 100%;
	  margin-bottom: 20px;
	  justify-content: center;
	}
  }
}